<template>
  <v-card color="grey lighten-4" light>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Notifications</v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text>
        <v-container fluid>
          <v-list class="py-0">
            <v-list-item-group v-model="selected" color="color3">
              <template v-for="(item, i) in notifications">
                <v-list-item :key="item.id">
                  <v-list-item-action class="mr-3 clickable">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="clickable">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.body }}</v-list-item-subtitle>
                    <v-list-item-subtitle :class="{'color3--text': !item.read}">{{ when(item.dtCreated) }} ago</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="mr-3">
                    <v-icon color="color3" x-small v-if="!item.read">fas fa-circle</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="i < (notifications.length - 1)" :key="`${item.id}d`"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-card-text>
      <div class="pa-3 pt-0 text-end">
        <v-btn
          color="color3"
          x-small text icon
          @click.stop="load"
        >
          <v-icon>fas fa-sync</v-icon>
        </v-btn>
      </div>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as mutations from '@/store/MutationTypes'

export default {
  data () {
    return {
      history: [],
      loading: false,
      page: null,
      selected: null
    }
  },
  computed: {
    ...mapGetters(['user', 'unread', 'notifications']),
    selectedNote () {
      return this.selected && this.notifications[this.selected]
    }
  },
  methods: {
    clicked (n) {
      this.markAsRead(n)
      this.$router.push(n.actionURL)
      this.menu = false
    },
    when (dtCreated) {
      var x = moment()
      var y = moment(dtCreated)
      var duration = moment.duration(x.diff(y))
      return duration.humanize()
    },
    load () {
      this.loading = true
      this.$VBL.user.notification.get(this.page)
        .then(r => {
          r.data && this.$store.commit(mutations.SET_NOTIFICATIONS, r.data)
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(f => {
          this.loading = false
        })
    },
    markAsRead (item) {
      if (item.read) return
      this.$VBL.user.notification.markAs('read', { ids: [item.id] })
        .then(r => {
          item.read = true
        })
        .catch(e => console.log(e.response))
        .finally(f => {
          this.loading = false
        })
    },
    markShown () {
      this.$VBL.user.notification.markAs('shown', { ids: [] })
        .catch(e => console.log(e.response))
        .finally(f => {
          clearTimeout(this.to)
        })
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    selectedNote: function (v) {
      if (v && v.actionURL) {
        this.$router.push(v.actionURL)
      }
    },
    menu: function (val) {
      clearTimeout(this.to)
      if (val) {
        this.to = setTimeout(this.markShown, 1313)
      }
    }
  }
}
</script>

<style>

</style>
