<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h1>{{fullname}}</h1>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <notification-history></notification-history>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import NotificationHistory from '@/components/User/NotificationHistory.vue'

export default {
  data () {
    return {

    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    fullname () {
      return this.user && [this.user.firstName, this.user.middleName, this.user.lastName].join(' ')
    }
  },
  components: {
    'notification-history': NotificationHistory
  },
  methods: {

  },
  created () {

  }
}
</script>
